<template>
    <div id="sc-dashboard">
        <h3>Dashboard</h3>
        <hr />

        <!-- Loading Spinner -->
        <div v-if="ticketsList.length == 0" class="sc-loading-spinner">
            <b-spinner variant="primary" label=""></b-spinner>
        </div>
        <div v-else id="sc-report-container">
            <!-- <BarChart :ticketsList="ticketsList" /> -->

            <iframe
                id="sc-iframe-dashboard"
                width="100%"
                height="920px"
                src="https://datastudio.google.com/embed/reporting/061bfdca-2913-40f6-b64b-4d83a059ad0b/page/PBbkC"
                frameborder="0"
                style="border:0"
                allowfullscreen
            ></iframe>

            <div id="sc-report-product-row-1">
                <div class="sc-report-product">
                    <h3>Bulk</h3>
                    <hr />
                    <div class="sc-daily">
                        <span>Today: </span><span class="sc-total-value">{{ parseInt(this.bulk.daily) }}</span>
                    </div>
                    <div class="sc-weekly">
                        <span>Weekly: </span><span class="sc-total-value"> {{ parseInt(this.bulk.weekly) }}</span>
                    </div>
                    <div class="sc-weekly">
                        <span>Monthly: </span><span class="sc-total-value"> {{ parseInt(this.bulk.monthly) }}</span>
                    </div>
                    <!-- <div class="sc-monthly">
                        <span>Total: </span
                        ><span class="sc-total-value">
                            {{ this.bulk.total }}
                        </span>
                    </div> -->
                </div>
                <div class="sc-report-product">
                    <h3>Bagged</h3>
                    <hr />
                    <div class="sc-daily">
                        <span>Today: </span><span class="sc-total-value"> {{ parseInt(this.bagged.daily) }}</span>
                    </div>
                    <div class="sc-weekly">
                        <span>Weekly: </span><span class="sc-total-value">{{ parseInt(this.bagged.weekly) }}</span>
                    </div>
                    <div class="sc-weekly">
                        <span>Monthly: </span><span class="sc-total-value"> {{ parseInt(this.bagged.monthly) }}</span>
                    </div>
                    <!-- <div class="sc-monthly">
                        <span>Total: </span
                        ><span class="sc-total-value">
                            {{ this.bagged.total }}
                        </span>
                    </div> -->
                </div>

                <div class="sc-report-product">
                    <h3>Accessories</h3>
                    <hr />
                    <div class="sc-daily">
                        <span>Today: </span><span class="sc-total-value">{{ parseInt(this.accessories.daily) }}</span>
                    </div>
                    <div class="sc-weekly">
                        <span>Weekly: </span><span class="sc-total-value">{{ parseInt(this.accessories.weekly) }}</span>
                    </div>
                    <div class="sc-weekly">
                        <span>Monthly: </span><span class="sc-total-value">{{ parseInt(this.accessories.monthly) }}</span>
                    </div>

                    <!-- <div class="sc-monthly">
                        <span>Total: </span
                        ><span class="sc-total-value">
                            {{ this.accessories.total }}
                        </span>
                    </div> -->
                </div>
            </div>

            <div id="sc-report-product-row-2">
                <div class="sc-report-product">
                    <h3>Delivery</h3>
                    <hr />
                    <div class="sc-daily">
                        <span>Today: </span><span class="sc-total-value">{{ parseInt(this.delivery.daily) }}</span>
                    </div>
                    <div class="sc-weekly">
                        <span>Weekly: </span><span class="sc-total-value">{{ parseInt(this.delivery.weekly) }}</span>
                    </div>
                    <div class="sc-weekly">
                        <span>Monthly: </span><span class="sc-total-value">{{ parseInt(this.delivery.monthly) }}</span>
                    </div>

                    <!-- <div class="sc-monthly">
                        <span>Total: </span
                        ><span class="sc-total-value">
                            {{ this.delivery.total }}
                        </span>
                    </div> -->
                </div>

                <div class="sc-report-product">
                    <h3>Retail</h3>
                    <hr />
                    <div class="sc-daily">
                        <span>Today: </span><span class="sc-total-value">{{ parseInt(this.retail.daily) }}</span>
                    </div>
                    <div class="sc-weekly">
                        <span>Weekly: </span><span class="sc-total-value">{{ parseInt(this.retail.weekly) }}</span>
                    </div>
                    <div class="sc-weekly">
                        <span>Monthly: </span><span class="sc-total-value">{{ parseInt(this.retail.monthly) }}</span>
                    </div>

                    <!-- <div class="sc-monthly">
                        <span>Total: </span
                        ><span class="sc-total-value">
                            {{ this.retail.total }}
                        </span>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import APICalls from "./../ticket/APICalls";
import util from "./../../util/util";
// import BarChart from "./BarChart";
// import { mapState, mapActions } from "vuex";

export default {
    name: "Dashboard",
    // components: { BarChart },
    data() {
        return {
            ticketsList: [],
            bulk: {
                total: 0,
                daily: 0,
                weekly: 0,
                monthly: 0,
            },
            bagged: {
                total: 0,
                daily: 0,
                weekly: 0,
                monthly: 0,
            },
            accessories: {
                total: 0,
                daily: 0,
                weekly: 0,
                monthly: 0,
            },
            delivery: {
                total: 0,
                daily: 0,
                weekly: 0,
                monthly: 0,
            },
            retail: {
                total: 0,
                daily: 0,
                weekly: 0,
                monthly: 0,
            },
        };
    },
    computed: {
        // ...mapState("ticket", ["tickets"]),
    },
    methods: {
        // ...mapActions("ticket", ["setTickets"]),
        setDashboard() {
            _.each(this.ticketsList, (ticket) => {
                let lineitems = ticket.lineitems;
                let createdAt = _.get(ticket, "createdAt");
                if (lineitems) {
                    _.each(lineitems, (item) => {
                        let itemType = _.get(item, "item.itemType.name", "");
                        let quantity = _.get(item, "quantity", 0);
                        this.setCategoryValues(itemType, quantity, createdAt);
                    });
                }
            });
        },
        setCategoryValues(itemType, quantity, createdAt) {
            let pastDay = parseInt(util.getTicketPastDay(createdAt));
            switch (itemType) {
                case "Bulk":
                    this.setBulk(quantity, pastDay);
                    break;

                case "Bagged":
                    this.setBagged(quantity, pastDay);
                    break;

                case "Accessories":
                    this.setAccessories(quantity, pastDay);
                    break;

                case "Delivery":
                    this.setDelivery(quantity, pastDay);
                    break;

                case "Retail":
                    this.setRetail(quantity, pastDay);
                    break;
            }
        },
        setBulk(quantity, pastDay) {
            this.bulk.total += quantity;
            if (pastDay <= 0) {
                this.bulk.daily += quantity;
            }

            if (pastDay <= 7) {
                this.bulk.weekly += quantity;
            }

            if (pastDay <= 30) {
                this.bulk.monthly += quantity;
            }
        },
        setBagged(quantity, pastDay) {
            this.bagged.total += quantity;
            if (pastDay <= 0) {
                this.bagged.daily += quantity;
            }

            if (pastDay <= 7) {
                this.bagged.weekly += quantity;
            }

            if (pastDay <= 30) {
                this.bagged.monthly += quantity;
            }
        },
        setAccessories(quantity, pastDay) {
            this.accessories.total += quantity;
            if (pastDay <= 0) {
                this.accessories.daily += quantity;
            }

            if (pastDay <= 7) {
                this.accessories.weekly += quantity;
            }

            if (pastDay <= 30) {
                this.accessories.monthly += quantity;
            }
        },
        setDelivery(quantity, pastDay) {
            this.delivery.total += quantity;
            if (pastDay <= 0) {
                this.delivery.daily += quantity;
            }

            if (pastDay <= 7) {
                this.delivery.weekly += quantity;
            }

            if (pastDay <= 30) {
                this.delivery.monthly += quantity;
            }
        },
        setRetail(quantity, pastDay) {
            this.retail.total += quantity;
            if (pastDay <= 0) {
                this.retail.daily += quantity;
            }

            if (pastDay <= 7) {
                this.retail.weekly += quantity;
            }

            if (pastDay <= 30) {
                this.retail.monthly += quantity;
            }
        },
        async getTickets() {
            try {
                let tickets = await APICalls.getTickets();
                this.ticketsList = tickets;
                // this.setTickets(tickets);
            } catch (e) {
                console.log(e);
                this.$router.push({ path: "/login" });
            }
        },
    },
    mounted() {
        if (util.isUserMasterAdmin()) {
            this.getTickets()
                .then(() => {
                    this.setDashboard();
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            this.ticketsList = [];
        }
    },
};
</script>

<style lang="scss">
#sc-report-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    #sc-iframe-dashboard {
        margin-bottom: 60px;
    }

    #sc-report-product-row-1 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 40px;
    }

    #sc-report-product-row-2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 40px;
    }

    .sc-report-product {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid black;
        border-radius: 5px;
        width: 30%;
        height: 300px;
        padding: 10px;

        hr {
            background-color: #007bc4;
            width: 100%;
            height: 2px;
        }

        .sc-daily,
        .sc-weekly,
        .sc-monthly {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 20px;
            margin-top: 10px;

            span:nth-child(1) {
                font-size: 20px;
                width: 50%;
                text-align: left;
            }

            span:nth-child(2) {
                font-size: 20px;
                font-weight: bold;
                width: 20%;
                text-align: left;
            }
        }
    }
}
</style>
